import { ACTION_STATUSES } from 'shared/consts';
import { RootState } from '../reducer';
import { SurveyState } from 'store/survey/types';
import { createSelector } from 'reselect';

export const surveyStateSelector = (state: RootState): SurveyState => state.survey;

export const selectSurveyIsLoading = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.getSurvey.status === ACTION_STATUSES.PENDING,
);

export const selectSurveyHasFetched = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.getSurvey.status === ACTION_STATUSES.FULFILLED
    || surveyState.getSurvey.status === ACTION_STATUSES.REJECTED,
);

export const selectSurveyStep = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.currentStep ?? null,
);

export const selectSurvey = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.survey ?? null,
);

export const selectQuestions = createSelector(
  selectSurvey,
  (survey) => survey?.questions?.sort((a, b) => a.order - b.order) ?? null,
);

export const selectRating = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.rating ?? null,
);

export const selectTotalSurveySteps = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.survey?.questions?.length ?? 0,
);

export const selectAnsweredQuestions = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.answeredQuestions,
);

export const selectMultiSelect = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.multiselect ?? null,
);

export const selectTextarea = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.textarea ?? null,
);

export const selectYesOrNo = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.yesOrNo ?? null,
);

export const selectDataForAnalytics = createSelector(
  surveyStateSelector, selectQuestions,
  (surveyState, questionsInState) => {
    const questions: Record<string, string | string[] | number>[] = [];
    const {
      multiselect, rating, textarea, yesOrNo,
    } = surveyState;
    const questionsArray = [multiselect, rating, textarea, yesOrNo];
    questionsArray.forEach((question: Record<string, string | string[] | number>) => {
      if (!question) {
        return;
      }
      Object.keys(question).forEach((uuid: string) => {
        const question_type = questionsInState.find((questionInState) => questionInState.uuid === uuid).type;
        questions.push({
          uuid,
          answer: question[uuid],
          question_type,
        });
      });
    });
    return questions;
  },
);

export const selectSurveyUuid = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.survey?.uuid,
);

export const selectSurveyInitialTimestamp = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.surveyStartTime,
);

export const selectSessionUuid = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState.sessionUuid,
);
