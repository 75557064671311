import { getImageUrl } from 'utils/image';
import { selectBuildingImage } from 'store/building/selectors';
import { leaveRating, sendToAnalytics } from 'store/survey/actions';
import { useSelector, useDispatch } from 'react-redux';
import { Question } from 'store/survey/types';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { selectRating } from 'store/survey/selectors';
import { RatingQuestionProps } from '@hqo/react-components-library/dist/organisms/hqo-question';

export interface RatingProps extends RatingQuestionProps {
  buttonDisabled: boolean,
  imageUrl: string,
  reportIssueCallback?: VoidFunction,
  reportIssueText: string,
  showProgressBar?: boolean
}

export const useRating = (question?: Question): RatingProps => {
  const rating = useSelector(selectRating);
  const buildingImage = useSelector(selectBuildingImage);
  const image = getImageUrl(buildingImage);
  const dispatch = useDispatch();
  const intl = useIntl();
  const reportIssue = useCallback(() => {
    window.location.href = 'mailto:support@hqo.co';
  }, []);
  const callback = useCallback((ratingForState: number) => {
    dispatch(leaveRating({ uuid: question.uuid, rating: ratingForState }));
    dispatch(sendToAnalytics.request(null, null));
  }, [question, dispatch]);
  if (!question) {
    return {
      buttonDisabled: true,
      callback: null,
      imageUrl: null,
      question: null,
      reportIssueText: null,
    };
  }
  return {
    buttonDisabled: !rating?.[question.uuid],
    reportIssueCallback: reportIssue,
    reportIssueText: intl.formatMessage({ id: 'report_issue' }),
    imageUrl: image,
    question: question.text,
    rating: rating?.[question.uuid],
    callback,
    showProgressBar: false,
  };
};
