import { StateType } from 'typesafe-actions';
import BuildingReducer from 'store/building/reducer';
import { combineReducers } from 'redux';
import configReducer from 'store/config/reducer';
import errorsReducer from 'store/errors/reducer';
import routerReducer from 'store/router/reducer';
import themeReducer from 'store/theme/reducer';
import userReducer from 'store/user/reducer';
import surveyReducer from 'store/survey/reducer';

const rootReducer = combineReducers({
  building: BuildingReducer,
  config: configReducer,
  errors: errorsReducer,
  router: routerReducer,
  survey: surveyReducer,
  theme: themeReducer,
  user: userReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
