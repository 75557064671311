import {
  changeStep,
  createSurveyAnswer,
  getSurvey,
  setInitialTimestamp,
  updateSurveyAnswer,
} from 'store/survey/actions';
import {
  selectQuestions,
  selectSurveyIsLoading,
  selectSurveyStep,
  selectSurveyHasFetched,
  selectTextarea,
  selectMultiSelect,
  selectAnsweredQuestions,
  selectSessionUuid,
} from 'store/survey/selectors';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Question, SurveyType } from 'store/survey/types';
import { selectSurveyUuid } from 'store/router/selectors';
import { useMultiSelect } from 'hooks/use-multiselect.hook';
import { useProps } from 'hooks/use-props.hook';
import { useRating } from 'hooks/use-rating.hook';
import { useTextarea } from 'hooks/use-textarea.hook';
import { useTotalQuestions } from 'hooks/use-total-questions.hook';
import { useYesOrNo } from 'hooks/use-yes-or-no.hook';
import { standardizeLocale } from 'utils/locale';
import { selectLocale } from 'store/config/selectors';
import { QuestionType } from '@hqo/react-components-library/dist/organisms/hqo-question';
import { track } from '@hqo/web-tracking';

interface State {
  surveyIsLoading: boolean;
  innerProps: ReturnType<typeof useProps>['innerProps'];
  outerProps: ReturnType<typeof useProps>['outerProps'];
  surveyIsDone: boolean;
  currentQuestion: Question;
  totalQuestions: number;
  goBack: VoidFunction;
  goForward: VoidFunction;
  handleClose: VoidFunction;
  isUserGeneratedSurvey: boolean;
}

export const useSurveyState = (): State => {
  const surveyUuid = useSelector(selectSurveyUuid);
  const surveyIsLoading = useSelector(selectSurveyIsLoading);
  const questions = useSelector(selectQuestions);
  const currentStep = useSelector(selectSurveyStep);
  const textarea = useSelector(selectTextarea);
  const multiSelect = useSelector(selectMultiSelect);
  const locale: string = useSelector(selectLocale);
  const surveyFetched: boolean = useSelector(selectSurveyHasFetched);
  const answeredQuestions = useSelector(selectAnsweredQuestions);
  const session_uuid = useSelector(selectSessionUuid);
  const isUserGeneratedSurvey = !Object.values(SurveyType as Record<string, string>).includes(surveyUuid.toLowerCase());
  const dispatch = useDispatch();

  const { totalQuestions } = useTotalQuestions();
  const currentQuestion = questions?.find(
    (question: Question) => question.order === currentStep ?? ({ type: null } as Question),
  );

  const surveyIsDone = currentStep === totalQuestions;

  const ratingProps = useRating(currentQuestion);
  const multiSelectProps = useMultiSelect(currentQuestion);
  const textareaProps = useTextarea(currentQuestion);
  const yesNoProps = useYesOrNo(currentQuestion);

  const { innerProps, outerProps } = useProps(
    currentQuestion,
    isUserGeneratedSurvey,
    ratingProps,
    multiSelectProps,
    textareaProps,
    yesNoProps,
  );

  useEffect(() => {
    dispatch(setInitialTimestamp());
  }, [dispatch]);

  useEffect(() => {
    if (surveyUuid && !questions && !surveyFetched && locale) {
      const localeMap = locale === 'en' ? 'en-us' : locale;
      dispatch(getSurvey.request({ uuid: surveyUuid, lang: standardizeLocale(localeMap) }));
    }
  }, [dispatch, surveyUuid, questions, surveyIsLoading, locale, surveyFetched]);

  const goBack = useCallback(() => {
    dispatch(changeStep(currentStep - 1));
    track('Click', { type: 'action', survey_mfe_back_selected: true }, { sendToPendo: true });
  }, [currentStep, dispatch]);

  const goForward = useCallback(() => {
    const value = (currentQuestion?.type === QuestionType.OPEN
      ? textarea?.[currentQuestion?.uuid]
      : multiSelect?.[currentQuestion?.uuid]) ?? null;

    dispatch(changeStep(currentStep + 1));
    if (isUserGeneratedSurvey && value) {
      const isAnswered = answeredQuestions.includes(currentQuestion?.uuid);
      const answerAction = isAnswered ? updateSurveyAnswer : createSurveyAnswer;
      dispatch(answerAction.request({
        questionUuid: currentQuestion?.uuid,
        ...currentQuestion?.type === QuestionType.OPEN ? { value } : {
          choice_uuids: (currentQuestion?.accept_multiple_choices ? value : [value]) as string[],
        },
        session_uuid,
      }));
    }
  }, [
    answeredQuestions, currentQuestion?.accept_multiple_choices, currentQuestion?.type, currentQuestion?.uuid,
    currentStep, dispatch, isUserGeneratedSurvey, multiSelect, textarea, session_uuid,
  ]);

  const handleClose = useCallback(() => {
    track('Click', { type: 'action', survey_mfe_done_selected: true }, { sendToPendo: true });
    // @ts-expect-error: SDK is initialized in index.html
    sdk.navigation.close();
    window.parent.postMessage('closeMFE', '*');
  }, []);

  return {
    surveyIsLoading,
    innerProps,
    outerProps,
    surveyIsDone,
    currentQuestion,
    totalQuestions,
    goBack,
    goForward,
    handleClose,
    isUserGeneratedSurvey,
  };
};
