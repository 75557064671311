import {
  AppThemeProvider,
  DefaultThemeWithFonts,
  defaultTheme,
} from '@hqo/react-components-library/dist/molecules/theme';

import { AppContentWrapperStyles } from 'components/app-content-wrapper/app-content-wrapper.styles';
import { Container } from 'hocs/shared-styles';
import { CustomFonts } from 'components/custom-fonts';
import React from 'react';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';

interface AppContentWrapperProps {
  children: React.ReactNode;
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps): JSX.Element => {
  const [hasBuilding, hasTheme, theme] = useBuildingTheme();

  if (!hasBuilding || !hasTheme) {
    return (
      <Container data-testid="loading-container" isLoading>
        <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
      </Container>
    );
  }

  return (
    <>
      <AppContentWrapperStyles customThemeFonts={theme.fonts} />
      <CustomFonts />
      <AppThemeProvider theme={theme as DefaultThemeWithFonts}>{children}</AppThemeProvider>
    </>
  );
};
