import { Route, Switch } from 'react-router-dom';

import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { DefaultRedirect } from './default-redirect';
import { ErrorPage } from 'pages/error';
import { Survey } from 'pages/mfe-survey';
import { PrivateRoute } from 'components/private-route';
import React from 'react';
import { NPS_PATH, ROOT_PATH, SURVEY_PATH } from 'shared/consts/paths';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Switch>
      <PrivateRoute path={[ROOT_PATH, NPS_PATH]} component={Survey} />
      <Route path="/error" component={ErrorPage} />
      <PrivateRoute path={SURVEY_PATH} component={Survey} />
      <Route component={DefaultRedirect} />
    </Switch>
  </AppContentWrapper>
);
