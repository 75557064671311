import { CDNURL } from 'shared/consts/global';

export const getImageUrl = (path?: string) => {
  if (!path) {
    return `${CDNURL}uploads/default_avatar.png`;
  }
  if (path.startsWith('uploads') || path.startsWith('default_images') || path.startsWith('assets')) {
    return CDNURL + path;
  }

  return path;
};
