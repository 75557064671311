import * as BuildingEpics from 'store/building/epics';

import * as errorsEpics from 'store/errors/epics';
import * as themeEpics from 'store/theme/epics';
import * as userEpics from 'store/user/epics';
import * as surveyEpics from 'store/survey/epics';

import { combineEpics } from 'redux-observable';

export default combineEpics(
  ...Object.values(userEpics),
  ...Object.values(themeEpics),
  ...Object.values(errorsEpics),
  ...Object.values(BuildingEpics),
  ...Object.values(surveyEpics),
);
