import * as actions from './actions';

import { mergeMap, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { selectBuildingName, selectBuildingUuid } from 'store/building/selectors';
import {
  selectDataForAnalytics,
  selectSurvey,
  selectSurveyInitialTimestamp,
} from 'store/survey/selectors';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { SendToAnalyticsPayload, SurveyType } from 'store/survey/types';
import { TrackEventTypeEnum } from '@hqo/analytics-package';
import { getTsatData } from 'store/survey/mocks';
import { isActionOf } from 'typesafe-actions';
import { selectLocale } from 'store/config/selectors';
import { currentUser, selectUserUuid } from 'store/user/selectors';
import { selectSurveyUuid } from 'store/router/selectors';
import { track } from '@hqo/web-tracking';
import { QuestionType } from '@hqo/react-components-library/dist/organisms/hqo-question';
import { reportError } from 'utils/reportError';

export const getSurveyEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getSurvey.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]): Observable<RootAction> => {
    if (Object.values(SurveyType as Record<string, string>).includes(payload.uuid.toLowerCase())) {
      const buildingName = selectBuildingName(state);
      const configLocale = selectLocale(state);
      const surveyType = selectSurveyUuid(state);
      const newData = getTsatData(configLocale, buildingName, surveyType as SurveyType);
      return of(actions.getSurvey.success(newData));
    }
    return apiClient(state)
      .getSurvey(payload.uuid, payload.lang)
      .pipe(
        map((xhrPayload) => {
          const questionUuids: string[] = xhrPayload.response?.data?.questions.map((q) => q.uuid);
          track('mfe_survey_get_survey_questions', {
            type: 'action',
            questions: questionUuids.join(','),
          });
          return actions.getSurvey.success(xhrPayload.response);
        }),
        catchError((error) => {
          reportError(error);
          return of(actions.getSurvey.failure(error));
        }),
      );
  }),
);

export const createSurveyAnswerEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.createSurveyAnswer.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]): Observable<RootAction> => {
    const survey = selectSurvey(state);
    const { company_uuid } = currentUser(state);
    const building_uuid = selectBuildingUuid(state);
    const { uuid, company_uuid: landlord_company_uuid } = survey;
    const { questionUuid, ...answer } = payload;
    const body = {
      ...answer, landlord_company_uuid, company_uuid, building_uuid,
    };
    return apiClient(state)
      .postSurveyAnswer(uuid, questionUuid, body)
      .pipe(
        mergeMap(() => ([
          actions.saveAnsweredQuestion(questionUuid),
          actions.createSurveyAnswer.success(null, null),
        ])),
        catchError((error) => {
          reportError(error);
          return of(actions.createSurveyAnswer.failure(error));
        }),
      );
  }),
);

export const updateSurveyAnswerEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.updateSurveyAnswer.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]): Observable<RootAction> => {
    const survey = selectSurvey(state);
    const { company_uuid } = currentUser(state);
    const building_uuid = selectBuildingUuid(state);
    const { uuid, company_uuid: landlord_company_uuid } = survey;
    const { questionUuid, ...answer } = payload;
    const body = {
      ...answer, landlord_company_uuid, company_uuid, building_uuid,
    };
    return apiClient(state)
      .putSurveyAnswer(uuid, questionUuid, body)
      .pipe(
        map(() => actions.updateSurveyAnswer.success(null, null)),
        catchError((error) => {
          reportError(error);
          return of(actions.updateSurveyAnswer.failure(error));
        }),
      );
  }),
);

export const sendToAnalyticsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.sendToAnalytics.request)),
  withLatestFrom(state$),
  switchMap(([, state]): Observable<RootAction> => {
    const surveyType = selectSurveyUuid(state);
    const isUserGeneratedSurvey = !Object.values(SurveyType as Record<string, string>)
      .includes(surveyType.toLowerCase());
    const user_uuid = selectUserUuid(state);
    const building_uuid = selectBuildingUuid(state);
    const questions = selectDataForAnalytics(state);
    const payload: SendToAnalyticsPayload = {
      event_type: TrackEventTypeEnum.USER_SURVEY_RESPONSE,
      properties: {
        user_uuid,
        building_uuid,
        survey_uuid: surveyType,
        questions,
        survey_session_id: selectSurveyInitialTimestamp(state),
        user_agent: navigator.userAgent,
      },
    };
    const userGeneratedSurveyQuestions = questions.map(({ question_type, ...rest }) => ({
      ...question_type === QuestionType.MULTIPLE_CHOICE ? { survey_mfe_choice_selected: rest }
        : { survey_mfe_open_question_entered: rest },
    }));
    track(TrackEventTypeEnum.USER_SURVEY_RESPONSE, {
      type: 'action',
      properties: {
        ...isUserGeneratedSurvey ? {
          questions: userGeneratedSurveyQuestions as unknown as string[],
          user_uuid,
          building_uuid,
        } : {
          questions: questions as unknown as string[],
        },
        survey_uuid: surveyType,
        survey_session_id: selectSurveyInitialTimestamp(state),
      },
    }, { sendToPendo: true });
    return apiClient(state)
      .sendToAnalytics(payload)
      .pipe(
        map(() => actions.sendToAnalytics.success(null, null)),
        catchError((error) => of(actions.sendToAnalytics.failure(error))),
      );
  }),
);

export const createSurveySessionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.createSurveySession.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]): Observable<RootAction> => {
    const { surveyUuid } = payload;
    return apiClient(state)
      .createSurveySession(surveyUuid)
      .pipe(
        map((xhrPayload) => actions.createSurveySession.success(xhrPayload.response)),
        catchError((error) => {
          reportError(error);
          return of(actions.createSurveySession.failure(error));
        }),
      );
  }),
);

export const updateSurveySessionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.updateSurveySession.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]): Observable<RootAction> => {
    const { surveyUuid, sessionUuid } = payload;
    return apiClient(state)
      .updateSurveySession(surveyUuid, sessionUuid)
      .pipe(
        map(() => actions.updateSurveySession.success(null, null)),
        catchError((error) => {
          reportError(error);
          return of(actions.updateSurveySession.failure(error));
        }),
      );
  }),
);
