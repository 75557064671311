import * as actions from './actions';

import { Observable, of } from 'rxjs';
import {
  catchError, filter, map, switchMap, withLatestFrom,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';

export const getBuildingEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getBuilding.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]): Observable<RootAction> => apiClient(state)
    .getBuilding(payload)
    .pipe(
      map((xhrPayload) => actions.getBuilding.success(xhrPayload.response.data.building)),
      catchError((error) => of(actions.getBuilding.failure(error))),
    )),
);
