import { DefaultThemeWithFonts, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { getBuilding } from 'store/building/actions';
import { getBuildingTheme } from 'store/theme/actions';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { selectBrandTheme } from 'store/theme/selectors';
import { selectBuilding } from 'store/building/selectors';
import { useSearchParams } from './use-search-params.hook';

export const useBuildingTheme = (): [boolean, boolean, DefaultThemeWithFonts] => {
  const dispatch = useDispatch();
  const { buildingUuid } = useSearchParams();
  const brandTheme = useSelector(selectBrandTheme);
  const building = useSelector(selectBuilding);
  const [sdkCalled, setSdkCalled] = useState(false);
  const [buildingTheme, setBuildingTheme] = useState(null);
  // @ts-expect-error: SDK is initialized in index.html
  sdk.on('getBuildingResponse', (data) => {
    localStorage.setItem('buildingUuid', data.uuid);
    dispatch(getBuildingTheme.request(data.uuid as string));
    dispatch(getBuilding.request(data.uuid as string));
  });

  useEffect(() => {
    if (buildingUuid) {
      if (!building) {
        dispatch(getBuilding.request(buildingUuid as string));
      }
      if (!brandTheme) {
        dispatch(getBuildingTheme.request(buildingUuid as string));
      }
    } else if (!sdkCalled) {
      // @ts-expect-error: SDK is initialized in index.html
      sdk.identity.getBuilding();
      setSdkCalled(true);
    }
  }, [dispatch, buildingUuid, building, brandTheme, sdkCalled]);

  useEffect(() => {
    let theme = defaultTheme;
    if (brandTheme) {
      theme = {
        colors: {
          ...defaultTheme.colors,
          $primary: brandTheme.primary_color,
          $secondary: brandTheme.secondary_color,
          // @ts-expect-error: Set these colors anyway
          primary: brandTheme.primary_color,
          secondary: brandTheme.secondary_color,
          fontColor: brandTheme.primary_font_color ?? hqoTheme.colors.greys.adminBlack,
        },
        fonts: brandTheme.font?.body?.font_family ? [`${brandTheme.font?.body?.font_family}`, ...hqoTheme.fonts] : null,
      };
    }
    setBuildingTheme(theme);
  }, [brandTheme]);

  return [!!building, !!buildingTheme, buildingTheme];
};
