import * as Sentry from '@sentry/react';

interface ErrorProps {
  message?: string;
  error?: Error;
}

export const reportError: ({ message, error }: ErrorProps) => void = ({ message, error }: ErrorProps): void => {
  if (error && message) {
    Sentry.addBreadcrumb({ message });
    Sentry.captureException(error);
  } else if (message) {
    Sentry.captureMessage(message);
  }
};
