import React, { useCallback } from 'react';

import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const Header = styled.h1`
  width: 100%;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;

export const ErrorPage = (): JSX.Element => {
  const intl = useIntl();
  const handleClose = useCallback(() => {
    window.parent.postMessage('closeMFE', '*');
    // @ts-expect-error: SDK is initialized in index.html
    sdk.navigation.close();
  }, []);
  return (
    <>
      <Header data-testid="error-header">{intl.formatMessage({ id: 'error_loading_survey' })}</Header>
      <StyledButton
        data-testid="error-closeButton"
        onClick={handleClose}
        text={intl.formatMessage({ id: 'error_exit_survey' })}
      />
    </>
  );
};
