import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { SurveyState } from 'store/survey/types';

export const INITIAL_STATE: SurveyState = {
  survey: null,
  getSurvey: {
    status: null,
    error: null,
  },
  currentStep: 0,
  answeredQuestions: [],
  rating: null,
  multiselect: null,
  textarea: null,
  yesOrNo: null,
  surveyStartTime: null,
  createSurveySession: {
    status: null,
    error: null,
  },
  sessionUuid: null,
  updateSurveySession: {
    status: null,
    error: null,
  },
};

export const now = Date.now();

const getSurveyRequestHandler = (state: SurveyState): SurveyState => ({
  ...state,
  getSurvey: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getSurveySuccessHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.getSurvey.success>,
): SurveyState => ({
  ...state,
  survey: payload.data,
  getSurvey: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getSurveyFailureHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.getSurvey.failure>,
): SurveyState => ({
  ...state,
  getSurvey: {
    error: payload,
    status: ACTION_STATUSES.REJECTED,
  },
});

const changeStepHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.changeStep>,
): SurveyState => ({
  ...state,
  currentStep: payload,
});

const saveAnsweredQuestionHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.saveAnsweredQuestion>,
): SurveyState => ({
  ...state,
  answeredQuestions: [...state.answeredQuestions, payload],
});

const leaveRatingHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.leaveRating>,
): SurveyState => ({
  ...state,
  rating: {
    ...state.rating,
    [payload.uuid]: payload.rating,
  },
});

const toggleMultiSelectHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.toggleMultiSelect>,
): SurveyState => ({
  ...state,
  multiselect: {
    ...state.multiselect,
    [payload.uuid]: payload.value,
  },
});

const addToTextareaHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.addToTextarea>,
): SurveyState => ({
  ...state,
  textarea: {
    ...state.textarea,
    [payload.uuid]: payload.value,
  },
});

const addToYesOrNoHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.addToYesOrNo>,
): SurveyState => ({
  ...state,
  yesOrNo: {
    ...state.yesOrNo,
    [payload.uuid]: payload.value,
  },
});

const setInitialTimestampHandler = (
  state: SurveyState,
): SurveyState => ({
  ...state,
  surveyStartTime: now,
});

const createSurveySessionRequestHandler = (state: SurveyState): SurveyState => ({
  ...state,
  createSurveySession: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const createSurveySessionSuccessHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.createSurveySession.success>,
): SurveyState => ({
  ...state,
  sessionUuid: payload.data?.sessionUuid,
  createSurveySession: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const createSurveySessionFailureHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.createSurveySession.failure>,
): SurveyState => ({
  ...state,
  sessionUuid: null,
  createSurveySession: {
    error: payload,
    status: ACTION_STATUSES.REJECTED,
  },
});

const updateSurveySessionRequestHandler = (state: SurveyState): SurveyState => ({
  ...state,
  updateSurveySession: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const updateSurveySessionSuccessHandler = (
  state: SurveyState,
): SurveyState => ({
  ...state,
  updateSurveySession: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const updateSurveySessionFailureHandler = (
  state: SurveyState,
  { payload }: ActionType<typeof actions.updateSurveySession.failure>,
): SurveyState => ({
  ...state,
  updateSurveySession: {
    error: payload,
    status: ACTION_STATUSES.REJECTED,
  },
});

const SurveyReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.getSurvey.request, getSurveyRequestHandler)
  .handleAction(actions.getSurvey.success, getSurveySuccessHandler)
  .handleAction(actions.getSurvey.failure, getSurveyFailureHandler)
  .handleAction(actions.saveAnsweredQuestion, saveAnsweredQuestionHandler)
  .handleAction(actions.changeStep, changeStepHandler)
  .handleAction(actions.leaveRating, leaveRatingHandler)
  .handleAction(actions.toggleMultiSelect, toggleMultiSelectHandler)
  .handleAction(actions.addToTextarea, addToTextareaHandler)
  .handleAction(actions.addToYesOrNo, addToYesOrNoHandler)
  .handleAction(actions.setInitialTimestamp, setInitialTimestampHandler)
  .handleAction(actions.createSurveySession.request, createSurveySessionRequestHandler)
  .handleAction(actions.createSurveySession.success, createSurveySessionSuccessHandler)
  .handleAction(actions.createSurveySession.failure, createSurveySessionFailureHandler)
  .handleAction(actions.updateSurveySession.request, updateSurveySessionRequestHandler)
  .handleAction(actions.updateSurveySession.success, updateSurveySessionSuccessHandler)
  .handleAction(actions.updateSurveySession.failure, updateSurveySessionFailureHandler);

export default SurveyReducer;
