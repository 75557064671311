import * as BuildingActions from 'store/building/actions';
import * as configActions from 'store/config/actions';
import * as errorsActions from 'store/errors/actions';
import * as themeActions from 'store/theme/actions';
import * as userActions from 'store/user/actions';
import * as surveyActions from 'store/survey/actions';

import { CallHistoryMethodAction, routerActions } from 'connected-react-router';
import { LocationState, Path } from 'history';

import { ActionType } from 'typesafe-actions';

// TODO: remove RouterActions custom types after https://github.com/supasate/connected-react-router/issues/286 fixed
type Push = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;
type Replace = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;

interface RouterActions {
  push: Push;
  replace: Replace;
  // go: Go; etc.
}

const actions = {
  router: routerActions as RouterActions,
  user: userActions,
  theme: themeActions,
  config: configActions,
  errors: errorsActions,
  Building: BuildingActions,
  surveyActions,
};

export type RootAction = ActionType<typeof actions>;
export default actions;
