import { addToTextarea, sendToAnalytics } from 'store/survey/actions';
import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormFieldProps } from '@hqo/react-components-library/dist/atoms/form-field';
import { Question } from 'store/survey/types';
import { selectTextarea } from 'store/survey/selectors';
import { useIntl } from 'react-intl';

export interface TextareaProps extends Pick<FormFieldProps, 'value' | 'placeholder' | 'label'> {
  buttonDisabled: boolean;
  callback: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholderText: string;
}

export const useTextarea = (question?: Question): TextareaProps => {
  const textarea = useSelector(selectTextarea);
  const dispatch = useDispatch();
  const timer: { current: ReturnType<typeof setTimeout> } = useRef(null);
  const intl = useIntl();

  const callback = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(addToTextarea({ uuid: question.uuid, value: event.target.value }));
      if (timer.current) {
        return false;
      }
      timer.current = setTimeout(() => {
        dispatch(sendToAnalytics.request(null, null));
        timer.current = null;
      }, 1000);
      return () => {
        clearTimeout(timer.current);
      };
    },
    [question, dispatch],
  );

  if (!question) {
    return {
      buttonDisabled: true,
      callback: null,
      placeholderText: null,
      label: null,
    };
  }

  return {
    buttonDisabled: question.required && !textarea?.[question.uuid],
    value: textarea?.[question.uuid] || '',
    callback,
    label: question.description,
    placeholderText: question.placeholderText ?? intl.formatMessage({ id: 'placeholder' }),
  };
};
