import { useDispatch, useSelector } from 'react-redux';

import { Question } from 'store/survey/types';
import { YesNoQuestionProps } from '@hqo/react-components-library/dist/organisms/hqo-question';
import { addToYesOrNo, sendToAnalytics } from 'store/survey/actions';
import { selectYesOrNo } from 'store/survey/selectors';
import { useCallback } from 'react';

declare type YesOrNo = 'yes' | 'no';

export interface YesNoProps extends Omit<YesNoQuestionProps, 'value'> {
    buttonDisabled: boolean,
    value?: YesOrNo,
}

export const useYesOrNo = (question?: Question): YesNoProps => {
  const yesOrNo = useSelector(selectYesOrNo);
  const dispatch = useDispatch();

  const callback = useCallback((value: YesOrNo) => {
    dispatch(addToYesOrNo({ uuid: question.uuid, value }));
    dispatch(sendToAnalytics.request(null, null));
  }, [question, dispatch]);

  if (!question) {
    return {
      buttonDisabled: true,
      value: null,
      callback: null,
    };
  }

  return {
    buttonDisabled: !yesOrNo?.[question.uuid],
    value: yesOrNo?.[question.uuid],
    optionalYesText: question.optionalYesText,
    optionalNoText: question.optionalNoText,
    callback,
  };
};
