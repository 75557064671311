import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { useSelector } from 'react-redux';
import { selectSurveyUuid } from 'store/survey/selectors';

export const DefaultRedirect: React.FC = (): JSX.Element => {
  const [user, loading] = useCurrentUser();
  const surveyUuid = useSelector(selectSurveyUuid);

  if (user || loading) {
    return <Redirect to={`/${surveyUuid || 'tsat'}`} />;
  }

  return <Redirect to="/error" />;
};
