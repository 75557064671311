import { AppThemeProvider, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';

import { App } from 'components/app';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { HqoThemeProvider } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { IntlProvider } from 'components/intl-provider';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { history } from 'store/router/history';
import store from 'store';

export const Root: React.FC = (): JSX.Element => (
  <ReduxProvider store={store}>
    <IntlProvider>
      <HqoThemeProvider>
        <AppThemeProvider theme={defaultTheme}>
          <BrowserRouter>
            <ConnectedRouter history={history}>
              <App />
            </ConnectedRouter>
          </BrowserRouter>
        </AppThemeProvider>
      </HqoThemeProvider>
    </IntlProvider>
  </ReduxProvider>
);
