import React, { useEffect } from 'react';

import { Container } from 'hocs/shared-styles';
import { Router } from 'components/router';
import { initializeWebTracking } from '@hqo/web-tracking';
import { selectBuildingUuid } from 'store/building/selectors';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import subscribeToPendo from 'utils/pendo';
import { useExternalLogin } from 'hooks/use-external-login.hook';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentToken } from 'store/user/selectors';
import { selectApiUrl } from 'store/config/selectors';
import { useSurveySession } from 'hooks/use-survey-session.hook';

export const App: React.FC = (): JSX.Element => {
  const hasCurrentUser = useExternalLogin();
  const intl = useIntl();
  useSurveySession();

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'title' });
  }, [intl]);

  useEffect(() => {
    if (!window?.pendo) {
      subscribeToPendo();
    }
  }, []);

  const token = useSelector(currentToken);
  const currentBuildingUuid = useSelector(selectBuildingUuid);
  const apiUrl = useSelector(selectApiUrl);

  useEffect(() => {
    const { userAgent } = window.navigator;
    if (token || currentBuildingUuid || apiUrl) {
      initializeWebTracking(token, currentBuildingUuid, 'mfe_survey', userAgent, apiUrl);
    }
  }, [token, currentBuildingUuid, apiUrl]);

  if (!hasCurrentUser) {
    return (
      <Container isLoading>
        <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
      </Container>
    );
  }

  return <Router />;
};
