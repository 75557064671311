/* eslint-disable */

declare global {
  interface Window {
    pendo: pendo.Pendo
  }
}

// This is pendo's script tag. Added some ts-expect-errors because TS doesn't like this function
/* istanbul ignore next */
export const subscribeToPendo = (apiKey = 'd8669700-64ff-44fd-4fbd-1f5658afd3b8') => {
  // @ts-expect-error: This script comes from Pendo
  (function(p, e, n, d, o) {
      // @ts-expect-error: This script comes from Pendo
      let v: string | any[]; let w; let x; let y; let z; o = p[d] = p[d] || {}; o._q = [];
      v = [ 'initialize', 'identify', 'updateOptions', 'pageLoad' ]; for(w = 0, x = v.length; w < x; ++w) {
          (function(m) {
              o[m] = o[m] || function() {o._q[m === v[0] ? 'unshift' : 'push']([ m ].concat([].slice.call(arguments, 0)));};
          })(v[w]);
      }
      // @ts-expect-error: This script comes from Pendo
      y = e.createElement(n); y.async = !0; y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      z = e.getElementsByTagName(n)[0]; z?.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
};

// TODO: Update this type once product says what info they want sent
export const initializePendo = (analytics: { visitor: { id: string; }; account: { id: string; }; }) => {
  const { pendo } = window;

  if (!pendo) {
    // eslint-disable-next-line no-console
    console.log('Pendo is not on the window object');
    return;
  }

  const accountId = pendo.getAccountId && pendo.getAccountId();
  if (accountId === null) {
    pendo.initialize(analytics);
    pendo.identify(analytics);
    return;
  }
};

export default subscribeToPendo;
