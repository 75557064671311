import { flatten } from 'utils';
import messagesInCanadianFrench from 'lang/fr-CA.json';
import messagesInDutch from 'lang/nl-NL.json';
import messagesInEnglish from 'lang/en.json';
import messagesInFrench from 'lang/fr-FR.json';
import messagesInGerman from 'lang/de-DE.json';
import messagesInSpanish from 'lang/es-ES.json';
import messagesInNorweigan from 'lang/nb-NO.json';
import messagesInItalian from 'lang/it-IT.json';
import messagesInPolish from 'lang/pl.json';
import messagesInEnglishGb from 'lang/en-GB.json';
import messagesInPortuguese from 'lang/pt-PT.json';
import { useMemo } from 'react';

export const DEFAULT_LOCALE = 'en';

const messagesMap = new Map<string, Record<string, unknown>>([
  ['en', messagesInEnglish.survey],
  ['es-es', messagesInSpanish.survey],
  ['fr-fr', messagesInFrench.survey],
  ['fr-ca', messagesInCanadianFrench.survey],
  ['de-de', messagesInGerman.survey],
  ['nl-nl', messagesInDutch.survey],
  ['nb-no', messagesInNorweigan.survey],
  ['it-it', messagesInItalian.survey],
  ['pl', messagesInPolish.survey],
  ['pt-pt', messagesInPortuguese.survey],
  ['en-gb', messagesInEnglishGb.survey],
]);

export const getAvailableLocale = (userLocale: string): [string, string] => {
  const lowercaseLocale = userLocale.toLowerCase();
  if (messagesMap.has(lowercaseLocale)) {
    return [userLocale, lowercaseLocale];
  }

  const [lang] = lowercaseLocale.split('-');

  if (messagesMap.has(lang)) {
    return [lang, lowercaseLocale];
  }

  return [DEFAULT_LOCALE, DEFAULT_LOCALE];
};

interface LocaleData {
  messages: Record<string, string>;
  locale: string;
}

export const useLocaleData = (userLocale: string): LocaleData => useMemo(() => {
  const [messagesKey, locale] = getAvailableLocale(userLocale);

  return {
    locale,
    messages: { ...messagesMap.get('en'), ...flatten(messagesMap.get(messagesKey)) } as Record<string, string>,
  };
}, [userLocale]);
