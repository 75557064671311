import { Option } from '@hqo/react-components-library/dist/molecules/hqo-multiselect-chips';
import { QuestionType } from '@hqo/react-components-library/dist/organisms/hqo-question';

export interface RatingQuestionProps {
  rating?: number;
  max?: number;
  imageUrl: string;
  question: string;
  callback(value: number): void;
  dataTestId?: string;
}

export interface MultiselectQuestionProps {
  title: string;
  options: Option[];
  values: string[];
  dataTestId?: string;
  callback(value: string[]): void;
}

export interface QuestionChoice {
  text: string;
  uuid: string;
}

export interface Question {
  question?: string;
  helper?: string;
  order: number;
  type: QuestionType;
  uuid?: string;
  options?: QuestionOption[];
  placeholderText?: string;
  optionalYesText?: string;
  optionalNoText?: string;
  text?: string;
  description?: string;
  choices?: QuestionChoice[];
  accept_multiple_choices?: boolean;
  required?: boolean;
}

export interface QuestionOption extends Omit<Option, 'label'> {
  label: string
  value: string;
}

declare type YesOrNo = 'yes' | 'no';
export interface SurveyState {
  survey: Survey;
  getSurvey: AsyncActionTypes;
  currentStep: number;
  answeredQuestions: string[];
  rating: Record<string, number>;
  multiselect: Record<string, string[] | string>;
  textarea: Record<string, string>;
  yesOrNo: Record<string, YesOrNo>;
  surveyStartTime: number;
  createSurveySession: AsyncActionTypes;
  sessionUuid: string;
  updateSurveySession: AsyncActionTypes;
}

export interface Survey {
  company_uuid?: string;
  uuid: string;
  questions: Question[];
}

export interface SurveyAnswerPayload {
  questionUuid?: string;
  uuid?: string;
  value?: string;
  choice_uuids?: string[];
}

export interface SurveyAnswer extends SurveyAnswerPayload{
  landlord_company_uuid: string;
  company_uuid: string;
  building_uuid: string;
}

export interface SendToAnalyticsPayload {
  event_type: string;
  properties: {
    user_uuid: string;
    building_uuid: string;
    survey_uuid: string;
    questions: Record<string, string | string[] | number>[];
    survey_session_id: number;
    user_agent?: string;
  };
}

// eslint-disable-next-line no-shadow
export enum SurveyType {
  TSAT = 'tsat',
  NPS = 'nps'
}

export interface GetSurveyPayload {
  uuid: string,
  lang?: string,
}

export interface GetSurveyResponse {
  data: Survey;
}

interface ButtonText {
  Next: string;
  Skip: string;
}

export type ButtonTextType = keyof ButtonText;

// eslint-disable-next-line no-shadow
export enum ButtonTextEnum {
  Next = 'survey_mfe_next_selected',
  Skip = 'survey_mfe_skip_selected'
}

export interface CreateSurveySessionPayload {
  surveyUuid: string;
}

export interface CreateSurveySessionResponse {
  data: {
    sessionUuid: string;
  };
}

export interface UpdateSurveySessionPayload {
  surveyUuid: string;
  sessionUuid: string;
}
