import { GetSurveyResponse, SurveyState } from 'store/survey/types';
import { createIntl, createIntlCache, MessageFormatElement } from 'react-intl';

import { QuestionType } from '@hqo/react-components-library/dist/organisms/hqo-question';
import { survey as deDE } from 'lang/de-DE.json';
import { survey as en } from 'lang/en.json';
import { survey as esES } from 'lang/es-ES.json';
import { survey as frCA } from 'lang/fr-CA.json';
import { survey as frFR } from 'lang/fr-FR.json';
import { survey as nlNL } from 'lang/nl-NL.json';
import { survey as nbNO } from 'lang/nb-NO.json';
import { survey as itIT } from 'lang/it-IT.json';
import { survey as plPL } from 'lang/pl.json';
import { DEFAULT_LOCALE } from 'components/intl-provider/intl-provider.hooks';
import { SurveyType } from './types';

const localeMap: { [key: string]: Record<string, string> } = {
  en,
  'en-us': en,
  'es-es': esES,
  'fr-fr': frFR,
  'fr-ca': frCA,
  'de-de': deDE,
  'nl-nl': nlNL,
  'nb-no': nbNO,
  'it-it': itIT,
  'pl-pl': plPL,
};

export const flattenMessages = (nestedMessages: Record<string, string>, prefix = ''): Record<string, unknown> => (
  !nestedMessages
    ? {}
    : Object.keys(nestedMessages).reduce((messages, key) => {
      const value = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'string') {
        Object.assign(messages, { [prefixedKey]: value });
      } else {
        Object.assign(messages, flattenMessages(value, prefixedKey));
      }

      return messages;
    }, {}));

export const getTsatData = (locale: string, buildingName: string, surveyType: SurveyType): GetSurveyResponse => {
  const lowercaseLocale = locale.toLowerCase();
  const intl = createIntl({
    locale: lowercaseLocale,
    messages: {
      ...flattenMessages(localeMap.en),
      ...flattenMessages(localeMap[lowercaseLocale]),
    } as Record<string, MessageFormatElement[]>,
    defaultLocale: DEFAULT_LOCALE,
  },
  createIntlCache());
  const isNPS = surveyType?.toLowerCase() === SurveyType.NPS.toLowerCase();

  return {
    data: {
      uuid: 'tsat',
      questions: [
        {
          uuid: 'tsat-0',
          order: 0,
          text: intl.formatMessage({ id: isNPS ? 'rate_experience_nps' : 'rate_experience' }, { buildingName }),
          type: QuestionType.RATING,
        },
        {
          uuid: 'tsat-1',
          order: 1,
          text: intl.formatMessage({ id: 'most_important' }),
          description: intl.formatMessage({ id: 'helper' }),
          choices: [
            {
              text: intl.formatMessage({ id: 'option1' }),
              uuid: 'Amenities',
            },
            {
              text: intl.formatMessage({ id: 'option2' }),
              uuid: 'Retail / Restaurants',
            },
            {
              text: intl.formatMessage({ id: 'option3' }),
              uuid: 'Security',
            },
            {
              text: intl.formatMessage({ id: 'option4' }),
              uuid: 'Maintenance',
            },
            {
              text: intl.formatMessage({ id: 'option5' }),
              uuid: 'Location & Transportation',
            },
            {
              text: intl.formatMessage({ id: 'option6' }),
              uuid: 'Sustainability',
            },
            {
              text: intl.formatMessage({ id: 'option7' }),
              uuid: 'Community Feeling',
            },
            {
              text: intl.formatMessage({ id: 'option8' }),
              uuid: 'Property Team',
            },
            {
              text: intl.formatMessage({ id: 'option9' }),
              uuid: 'Cleanliness',
            },
          ],
          type: QuestionType.MULTIPLE_CHOICE,
          required: true,
        },
        {
          uuid: 'tsat-2',
          text: intl.formatMessage({ id: 'most_satisfied' }),
          description: intl.formatMessage({ id: 'helper' }),
          order: 2,
          choices: [
            {
              text: intl.formatMessage({ id: 'option1' }),
              uuid: 'Amenities',
            },
            {
              text: intl.formatMessage({ id: 'option2' }),
              uuid: 'Retail / Restaurants',
            },
            {
              text: intl.formatMessage({ id: 'option3' }),
              uuid: 'Security',
            },
            {
              text: intl.formatMessage({ id: 'option4' }),
              uuid: 'Maintenance',
            },
            {
              text: intl.formatMessage({ id: 'option5' }),
              uuid: 'Location & Transportation',
            },
            {
              text: intl.formatMessage({ id: 'option6' }),
              uuid: 'Sustainability',
            },
            {
              text: intl.formatMessage({ id: 'option7' }),
              uuid: 'Community Feeling',
            },
            {
              text: intl.formatMessage({ id: 'option8' }),
              uuid: 'Property Team',
            },
            {
              text: intl.formatMessage({ id: 'option9' }),
              uuid: 'Cleanliness',
            },
          ],
          type: QuestionType.MULTIPLE_CHOICE,
          required: true,
        },
        {
          uuid: 'tsat-3',
          text: intl.formatMessage({ id: 'could_improve' }),
          description: intl.formatMessage({ id: 'helper' }),
          order: 3,
          choices: [
            {
              text: intl.formatMessage({ id: 'option1' }),
              uuid: 'Amenities',
            },
            {
              text: intl.formatMessage({ id: 'option2' }),
              uuid: 'Retail / Restaurants',
            },
            {
              text: intl.formatMessage({ id: 'option3' }),
              uuid: 'Security',
            },
            {
              text: intl.formatMessage({ id: 'option4' }),
              uuid: 'Maintenance',
            },
            {
              text: intl.formatMessage({ id: 'option5' }),
              uuid: 'Location & Transportation',
            },
            {
              text: intl.formatMessage({ id: 'option6' }),
              uuid: 'Sustainability',
            },
            {
              text: intl.formatMessage({ id: 'option7' }),
              uuid: 'Community Feeling',
            },
            {
              text: intl.formatMessage({ id: 'option8' }),
              uuid: 'Property Team',
            },
            {
              text: intl.formatMessage({ id: 'option9' }),
              uuid: 'Cleanliness',
            },
            {
              text: intl.formatMessage({ id: 'option10' }),
              uuid: 'All good, no concerns',
            },
            {
              text: intl.formatMessage({ id: 'option11' }),
              uuid: 'Other',
            },
          ],
          type: QuestionType.MULTIPLE_CHOICE,
          required: true,
        },
        {
          uuid: 'tsat-4',
          text: intl.formatMessage({ id: 'additional_feedback' }),
          description: intl.formatMessage({ id: 'feedback_helper' }),
          order: 4,
          placeholderText: intl.formatMessage({ id: 'feedback_placeholder' }),
          type: QuestionType.OPEN,
          required: false,
        },
        {
          uuid: 'tsat-5',
          type: QuestionType.YESORNO,
          text: intl.formatMessage({ id: 'contact_directly' }),
          order: 5,
          optionalYesText: intl.formatMessage({ id: 'contact_directly_yes' }),
          optionalNoText: intl.formatMessage({ id: 'contact_directly_no' }),
          required: true,
        },
      ],
    },
  };
};

export const mockRating: SurveyState['rating'] = {
  'tsat-0': 2,
};

export const mockMultiselect: SurveyState['multiselect'] = {
  'tsat-1': ['Amenities', 'Security'],
};

export const mockTextarea: SurveyState['textarea'] = {
  'tsat-4': 'Test textarea text',
};

export const mockYesOrNo: SurveyState['yesOrNo'] = {
  'tsat-5': 'yes',
};
