import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  GetSurveyPayload,
  GetSurveyResponse,
  SurveyAnswerPayload,
  CreateSurveySessionPayload,
  CreateSurveySessionResponse,
  UpdateSurveySessionPayload,
} from 'store/survey/types';

declare type YesOrNo = 'yes' | 'no';

export const getSurvey = createAsyncAction('GET_SURVEY_REQUEST', 'GET_SURVEY_SUCCESS', 'GET_SURVEY_FAILURE')<
  GetSurveyPayload,
  GetSurveyResponse,
  Error
>();

export const createSurveyAnswer = createAsyncAction(
  'CREATE_SURVEY_ANSWER',
  'CREATE_SURVEY_ANSWER_SUCCESS',
  'CREATE_SURVEY_ANSWER_FAILURE',
)<
  SurveyAnswerPayload,
  null,
  Error
>();

export const updateSurveyAnswer = createAsyncAction(
  'UPDATE_SURVEY_ANSWER',
  'UPDATE_SURVEY_ANSWER_SUCCESS',
  'UPDATE_SURVEY_ANSWER_FAILURE',
)<
  SurveyAnswerPayload,
  null,
  Error
>();

export const saveAnsweredQuestion = createAction('SAVE_ANSWERED_QUESTION')<string>();

export const changeStep = createAction('CHANGE_STEP')<number>();

export const leaveRating = createAction('LEAVE_RATING')<{uuid: string, rating: number}>();

export const toggleMultiSelect = createAction('TOGGLE_MULTISELECT')<{uuid: string, value: string[] | string}>();

export const addToTextarea = createAction('ADD_TO_TEXTAREA')<{uuid: string, value: string}>();

export const addToYesOrNo = createAction('ADD_TO_YES_OR_NO')<{uuid: string, value: YesOrNo}>();

export const sendToAnalytics = createAsyncAction(
  'SEND_TO_ANALYTICS',
  'SEND_TO_ANALYTICS_SUCCESS',
  'SEND_TO_ANALYTICS_FAILURE',
)<
  null,
  null,
  Error
>();

export const setInitialTimestamp = createAction('SET_INITIAL_TIMESTAMP')();

export const createSurveySession = createAsyncAction(
  'CREATE_SURVEY_SESSION',
  'CREATE_SURVEY_SESSION_SUCCESS',
  'CREATE_SURVEY_SESSION_FAILURE',
)<
  CreateSurveySessionPayload,
  CreateSurveySessionResponse,
  Error
>();

export const updateSurveySession = createAsyncAction(
  'UPDATE_SURVEY_SESSION',
  'UPDATE_SURVEY_SESSION_SUCCESS',
  'UPDATE_SURVEY_SESSION_FAILURE',
)<
  UpdateSurveySessionPayload,
  null,
  Error
>();
