import { MultiProps } from 'hooks/use-multiselect.hook';
import { Question } from 'store/survey/types';
import { RatingProps } from 'hooks/use-rating.hook';
import { TextareaProps } from 'hooks/use-textarea.hook';
import { YesNoProps } from 'hooks/use-yes-or-no.hook';
import { useIntl } from 'react-intl';
import {
  MultiselectQuestionProps,
  QuestionType,
  RatingQuestionProps,
  SelectQuestionProps,
  TextareaQuestionProps,
  YesNoQuestionProps,
} from '@hqo/react-components-library/dist/organisms/hqo-question';

interface Response {
  innerProps: RatingQuestionProps
    | MultiselectQuestionProps
    | SelectQuestionProps
    | YesNoQuestionProps
    | TextareaQuestionProps;
  outerProps: {
    buttonDisabled: boolean;
    [key: string]: unknown;
  };
}

export const useProps = (
  currentQuestion: Question,
  isUserGeneratedSurvey: boolean,
  ratingProps?: RatingProps,
  multiSelectProps?: MultiProps,
  textareaProps?: TextareaProps,
  yesNoProps?: YesNoProps,
): Response => {
  const intl = useIntl();
  let innerProps = null;
  let outerProps = null;
  switch (currentQuestion?.type) {
  case QuestionType.RATING:
    innerProps = {
      rating: ratingProps.rating,
      imageUrl: ratingProps.imageUrl,
      question: ratingProps.question,
      callback: ratingProps.callback,
    };
    outerProps = {
      buttonDisabled: ratingProps.buttonDisabled,
      showProgressBar: ratingProps.showProgressBar,
      reportIssueCallback: ratingProps.reportIssueCallback,
      reportIssueText: intl.formatMessage({ id: 'report_issue' }),
    };
    break;
  case QuestionType.OPEN:
    innerProps = {
      value: textareaProps.value,
      callback: textareaProps.callback,
      placeholder: textareaProps.placeholderText,
      ...!isUserGeneratedSurvey && textareaProps.label && {
        label: textareaProps.label,
      },
    };
    outerProps = {
      buttonDisabled: textareaProps.buttonDisabled,
      ...isUserGeneratedSurvey && {
        questionDescription: textareaProps.label,
        isUserGeneratedSurvey,
      },
    };
    break;
  case QuestionType.YESORNO:
    innerProps = {
      value: yesNoProps.value,
      callback: yesNoProps.callback,
      optionalYesText: yesNoProps.optionalYesText,
      optionalNoText: yesNoProps.optionalNoText,
    };
    outerProps = {
      buttonDisabled: yesNoProps.buttonDisabled,
    };
    break;
  default:
    innerProps = {
      callback: multiSelectProps.callback,
      options: multiSelectProps.options,
      ...isUserGeneratedSurvey ? {
        value: multiSelectProps.value,
        isMultiple: multiSelectProps.isMultiple,
      } : {
        title: multiSelectProps.title,
        values: multiSelectProps.values,
      },
    };
    outerProps = {
      buttonDisabled: multiSelectProps.buttonDisabled,
      ...isUserGeneratedSurvey && {
        questionDescription: multiSelectProps.title,
        isUserGeneratedSurvey,
      },
    };
    break;
  }

  return { innerProps, outerProps };
};
