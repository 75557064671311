import { AjaxResponse, ajax } from 'rxjs/ajax';
import {
  SendToAnalyticsPayload, GetSurveyResponse, SurveyAnswer, CreateSurveySessionResponse,
} from 'store/survey/types';

import { BuildingResponse } from 'store/building/types';
import { BuildingTheme } from 'store/theme/types';
import { CurrentUserResponse } from 'store/user/types';
import { Observable } from 'rxjs';

export class ApiClient {
  constructor(private baseUrl: string, private authToken?: string | null) {}

  private get DefaultHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authToken,
      'HqO-App-Version': '4.0.0',
    };
  }

  getCurrentUser(): Observable<AjaxResponse<CurrentUserResponse>> {
    return ajax.get(`${this.baseUrl}/api/users/current`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme`, this.DefaultHeaders);
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}?include=languages`, this.DefaultHeaders);
  }

  getSurvey(surveyUuid: string, lang: string): Observable<AjaxResponse<GetSurveyResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v2/surveys/${surveyUuid}?lang=${lang}`, this.DefaultHeaders);
  }

  postSurveyAnswer(
    surveyUuid: string,
    questionUuid: string,
    body: SurveyAnswer,
  ): Observable<AjaxResponse<SurveyAnswer>> {
    return ajax.post(`${this.baseUrl}/api/tenant/v2/surveys/${surveyUuid}/question/${questionUuid}`,
      body, this.DefaultHeaders);
  }

  putSurveyAnswer(
    surveyUuid: string,
    questionUuid: string,
    body: SurveyAnswer,
  ): Observable<AjaxResponse<SurveyAnswer>> {
    return ajax.put(`${this.baseUrl}/api/tenant/v2/surveys/${surveyUuid}/question/${questionUuid}`,
      body, this.DefaultHeaders);
  }

  sendToAnalytics(payload: SendToAnalyticsPayload): Observable<AjaxResponse<void>> {
    return ajax.post(`${this.baseUrl}/api/analytics/v2/track`, payload, this.DefaultHeaders);
  }

  createSurveySession(surveyUuid: string): Observable<AjaxResponse<CreateSurveySessionResponse>> {
    return ajax.post(`${this.baseUrl}/api/tenant/v2/surveys/${surveyUuid}/session`, {}, this.DefaultHeaders);
  }

  updateSurveySession(surveyUuid: string, sessionUuid: string): Observable<AjaxResponse<CreateSurveySessionResponse>> {
    return ajax.patch(`${this.baseUrl}/api/tenant/v2/surveys/${surveyUuid}/session/${sessionUuid}`,
      {},
      this.DefaultHeaders);
  }
}
