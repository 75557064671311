import { Question, QuestionChoice } from 'store/survey/types';
import { sendToAnalytics, toggleMultiSelect } from 'store/survey/actions';
import { useDispatch, useSelector } from 'react-redux';

import { MultiselectQuestionProps } from '@hqo/react-components-library/dist/organisms/hqo-question';
import { Option } from '@hqo/react-components-library/dist/molecules/hqo-multiselect-chips';
import { selectMultiSelect } from 'store/survey/selectors';
import { useCallback } from 'react';

export interface MultiProps extends Omit<MultiselectQuestionProps, 'options' | 'title' | 'values'> {
  buttonDisabled: boolean;
  title?: string;
  options: Option[];
  values?: string[] | null;
  value?: string[] | string | null;
  isMultiple?: boolean;
}

export const useMultiSelect = (question?: Question): MultiProps => {
  const values = useSelector(selectMultiSelect);
  const dispatch = useDispatch();

  const callback = useCallback(
    (value: string[] | string) => {
      dispatch(toggleMultiSelect({ uuid: question.uuid, value }));
      dispatch(sendToAnalytics.request(null, null));
    },
    [question, dispatch],
  );
  if (!question || question.type === null || !question.choices) {
    return {
      buttonDisabled: true,
      title: null,
      callback: null,
      options: [],
      values: [],
      value: [],
      isMultiple: false,
    };
  }

  const valueData = values?.[question.uuid] ?? [];
  return {
    callback,
    options: question.choices.map(
      (choice: QuestionChoice) => ({ label: choice.text, value: choice.uuid }),
    ),
    title: question.description,
    values: valueData as string[],
    value: valueData,
    isMultiple: question.accept_multiple_choices,
    buttonDisabled: question.required && valueData.length === 0,
  };
};
