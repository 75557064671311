import { BuildingState, Building, Language } from 'store/building/types';
import { RootState } from '../reducer';
import { createSelector } from 'reselect';

export const buildingStateSelector = (state: RootState): BuildingState => state.building;

export const selectBuilding = createSelector(buildingStateSelector, (buildingState) => buildingState.building);

export const selectBuildingStatus = createSelector(
  buildingStateSelector,
  (buildingState) => buildingState.getBuilding.status,
);

export const selectBuildingTimeZone = createSelector(
  selectBuilding,
  (building: Building | null): string | null => building?.timezone ?? null,
);

export const selectBuildingLocale = createSelector(
  selectBuilding,
  (building: Building | null): string | null => building?.locale ?? null,
);

export const selectBuildingImage = createSelector(
  selectBuilding,
  (building: Building): string => building?.image_url ?? null,
);

export const selectBuildingName = createSelector(
  selectBuilding,
  (building: Building): string => building?.name ?? null,
);

export const selectBuildingUuid = createSelector(
  selectBuilding,
  (building: Building): string => building?.uuid ?? null,
);

export const selectBuildingLanguage = createSelector(
  selectBuilding,
  (building: Building): Language => {
    const FIRST_INDEX = 0;
    const languages = building?.languages ?? [];
    return languages.sort((a: Language, b: Language) => a.priority - b.priority)?.[FIRST_INDEX] ?? null;
  },
);
