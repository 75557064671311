import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { currentUser as currentUserSelector } from 'store/user/selectors';
import { externalLogin, getCurrentUser } from 'store/user/actions';
import { useViewParams } from './use-view-params.hook';

export const useExternalLogin = (): boolean => {
  const dispatch = useDispatch();
  const { authToken, apiUrl } = useViewParams();
  const currentUser = useSelector(currentUserSelector);

  useEffect((): void => {
    if (!currentUser) {
      if (authToken) {
        dispatch(externalLogin.request({ authToken: authToken ?? null, apiUrl: apiUrl ?? null }));
      } else {
        dispatch(getCurrentUser.request());
      }
    }
  }, [dispatch, apiUrl, authToken, currentUser]);

  return !!currentUser;
};
