import { currentUser, hasToken as hasTokenSelector } from 'store/user/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { User } from 'store/user/types';
import { getCurrentUser } from 'store/user/actions';
import { useEffect } from 'react';

export const useCurrentUser = (): [User | null, boolean] => {
  const user = useSelector(currentUser);
  const hasToken = useSelector(hasTokenSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user && hasToken) {
      dispatch(getCurrentUser.request());
    }
  }, [user, hasToken, dispatch]);

  return [user, !user && hasToken];
};
