import React, { useCallback } from 'react';

import { Container } from 'hocs/shared-styles';
import { ButtonTextEnum, ButtonTextType, Question } from 'store/survey/types';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { track } from '@hqo/web-tracking';
import { useIntl } from 'react-intl';
import { useSurveyState } from 'pages/mfe-survey/use-survey-state.hook';
import {
  HqoQuestion,
  MultiselectQuestionProps,
  QuestionType,
  RatingQuestionProps,
  SelectQuestionProps,
  TextareaQuestionProps,
  YesNoQuestionProps,
} from '@hqo/react-components-library/dist/organisms/hqo-question';

export const getButtonSkippable = (
  currentQuestion: Question,
  innerProps: RatingQuestionProps
    | MultiselectQuestionProps
    | SelectQuestionProps
    | YesNoQuestionProps
    | TextareaQuestionProps,
): boolean => {
  if (currentQuestion?.type === QuestionType.OPEN && !(innerProps as TextareaQuestionProps).value) {
    return true;
  }
  return false;
};

export const Survey = (): JSX.Element => {
  const {
    surveyIsLoading,
    goForward,
    goBack,
    innerProps,
    outerProps,
    surveyIsDone,
    currentQuestion,
    totalQuestions,
    handleClose,
    isUserGeneratedSurvey,
  } = useSurveyState();
  const intl = useIntl();

  const getButtonText: () => string = useCallback((): string => {
    if (currentQuestion?.type === QuestionType.OPEN
      && !(innerProps as TextareaQuestionProps).value
      && !currentQuestion?.required) {
      return intl.formatMessage({ id: 'skip' });
    }
    return intl.formatMessage({ id: 'next' });
  }, [currentQuestion?.type, currentQuestion?.required, innerProps, intl]);

  const onButtonClick: VoidFunction = useCallback((): void => {
    track('Click', {
      type: 'action', buttonType: getButtonText(), [ButtonTextEnum[getButtonText() as ButtonTextType]]: true,
    }, { sendToPendo: true });
    goForward();
  }, [getButtonText, goForward]);

  if (surveyIsLoading) {
    return (
      <Container isLoading data-testid="loader">
        <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
      </Container>
    );
  }

  if (surveyIsDone) {
    return (
      <div data-testid="done-survey">
        <HqoQuestion
          buttonDisabled={false}
          buttonCallback={handleClose}
          buttonText={intl.formatMessage({ id: 'done' })}
          feedbackData={{
            lineText1: intl.formatMessage({ id: 'feedbackSent1' }),
          }}
          questionText={intl.formatMessage({ id: 'feedbackSent' })}
          questionType={QuestionType.RATING}
          showFeedbackSent
          showButton
          isUserGeneratedSurvey={isUserGeneratedSurvey}
        />
      </div>
    );
  }

  return (
    <div data-testid="current-survey">
      <HqoQuestion
        buttonCallback={onButtonClick}
        buttonText={getButtonText()}
        buttonSkippable={getButtonSkippable(currentQuestion, innerProps)}
        currentStep={currentQuestion?.order + 1}
        questionText={currentQuestion?.text}
        questionData={innerProps}
        questionType={currentQuestion?.type}
        showProgressBar
        totalSteps={totalQuestions}
        stepOfText={intl.formatMessage({ id: 'step_of' },
          { currentStep: (currentQuestion?.order + 1)?.toString(), totalSteps: totalQuestions?.toString() })}
        backButtonText={intl.formatMessage({ id: 'back' })}
        onBack={goBack}
        buttonDisabled={outerProps.buttonDisabled}
        {...outerProps}
      />
    </div>
  );
};
