import { createSurveySession, updateSurveySession } from 'store/survey/actions';
import { currentUser as selectCurrentUser } from 'store/user/selectors';
import { Dispatch } from 'redux';
import { selectSessionUuid, selectSurveyStep } from 'store/survey/selectors';
import { selectSurveyUuid } from 'store/router/selectors';
import { SurveyType } from 'store/survey/types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTotalQuestions } from 'hooks/use-total-questions.hook';
import { User } from 'store/user/types';

export const useSurveySession: VoidFunction = (): void => {
  const currentUser: User = useSelector(selectCurrentUser);
  const surveyUuid: string = useSelector(selectSurveyUuid);
  const currentStep: number = useSelector(selectSurveyStep);
  const sessionUuid: string = useSelector(selectSessionUuid);

  const dispatch: Dispatch = useDispatch();
  const { totalQuestions } = useTotalQuestions();

  const isUserGeneratedSurvey = !Object.values(SurveyType as Record<string, string>).includes(surveyUuid.toLowerCase());
  const surveyIsDone: boolean = totalQuestions > 0 && currentStep === totalQuestions;

  useEffect(() => {
    if (currentUser && isUserGeneratedSurvey) {
      dispatch(createSurveySession.request({ surveyUuid }));
    }
  }, [currentUser, dispatch, isUserGeneratedSurvey, surveyUuid]);

  useEffect(() => {
    if (sessionUuid && surveyIsDone) {
      dispatch(updateSurveySession.request({ surveyUuid, sessionUuid }));
    }
  }, [currentStep, dispatch, sessionUuid, surveyIsDone, surveyUuid, totalQuestions]);
};
